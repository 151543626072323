import './new.scss'

const New = () => {
  return (
    <div>
        New
    </div>
  )
}

export default New