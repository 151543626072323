import './login.scss'

const login = () => {
  return (
    <div>
        Login.
    </div>
  )
}

export default login