import './single.scss'

const Single = () => {
  return (
    <div>
        Single
    </div>
  )
}

export default Single